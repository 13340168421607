@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,700,800);
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  color: hsl(209, 61%, 16%);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  color: hsl(209, 34%, 30%);
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 1rem;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Quiz
===============
*/

main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz-background {
  background: hsl(210, 36%, 96%) !important;
  background: var(--clr-grey-10) !important;
}
.quiz {
  width: 90vw;
  max-width: 1170px;
  max-width: var(--max-width);
  margin: 4rem auto;
  background: #fff;
  background: var(--clr-white);
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.quiz-small {
  max-width: 500px;
}
.container h2 {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.5;
  text-transform: none;
}
.answer-btn {
  display: block;
  width: 100%;
  margin: 0.75rem auto;
  background: hsl(205, 90%, 76%);
  background: var(--clr-primary-7);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: #222;
  color: var(--clr-black);
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 0;
  transition: all 0.3s linear;
  transition: var(--transition);
}
@media screen and (min-width: 576px) {
  .answer-btn {
    max-width: 60%;
  }
}
.answer-btn:hover {
  background: hsl(205, 78%, 60%);
  background: var(--clr-primary-5);
  color: #fff;
  color: var(--clr-white);
}
.correct-answers {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: right;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  color: hsl(125, 67%, 44%);
  color: var(--clr-green-dark);
}
.next-question,
.close-btn,
.submit-btn {
  border-radius: 0.25rem;
  border-radius: var(--radius);
  border-color: transparent;
  padding: 0.25rem 0.5rem;
  display: block;
  width: 35%;
  margin-left: auto;
  margin-top: 2rem;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  font-size: 1rem;
  background: #ffb100;
  color: #222;
  color: var(--clr-black);
  transition: all 0.3s linear;
  transition: var(--transition);
  cursor: pointer;
}
.next-question:hover {
  background: #805900;
  color: #ffb100;
}
/*
=============== 
Form
===============
*/

.setup-form h2 {
  margin-bottom: 2rem;
}
.form-control {
  margin-bottom: 2rem;
}
.form-control label {
  display: block;
  text-transform: capitalize;
  font-weight: 500;
  color: hsl(209, 34%, 30%);
  color: var(--clr-grey-3);
  margin-bottom: 0.5rem;
}
.form-input {
  border: none;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.error {
  color: hsl(360, 67%, 44%);
  color: var(--clr-red-dark);
  text-transform: capitalize;
}
.submit-btn {
  width: 100%;
  margin-top: 3rem;
}
/*
=============== 
Loading
===============
*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 0;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: hsl(205, 78%, 60%);
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s linear;
  transition: var(--transition);
  z-index: -1;
}
.isOpen {
  opacity: 1;
  z-index: 999;
}

.modal-content {
  background: #fff;
  background: var(--clr-white);
  width: 90vw;
  max-width: 620px;
  max-width: var(--fixed-width);
  padding: 3rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-align: center;
  position: relative;
}
.modal-content p {
  font-size: 1.5rem;
  text-transform: none;
}
.close-btn {
  margin-right: auto;
}

p {
   margin-bottom: 0;
}
.Loading-header {
    background-color: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

@media screen and (max-width: 998px) {
  #loaderBox {
    width: 25%;
  }
}

@media screen and (max-width: 798px) {
  #loaderBox {
    width: 30%;
  }
}

@media screen and (max-width: 548px) {
  #loaderBox {
    width: 35%;
  }
}
/** Styles for the 403 Page **/

.particle-error,
.permission_denied,
#tsparticles {
    width: 100%;
    height: 100vh;
    margin: 0px !important;
}

#tsparticles {
    position: fixed !important;
    opacity: 0.23;
}

.permission_denied {
    background: #24344c !important;
}

.permission_denied a {
    text-decoration: none;
}

.denied__wrapper {
    max-width: 390px;
    width: 100%;
    height: 390px;
    display: block;
    margin: 0 auto;
    position: relative;
    padding-top: 7vh;
}

.permission_denied h1 {
    text-align: center;
    color: #fff;
    font-family: "Dosis", sans-serif;
    font-size: 100px;
    margin-bottom: 0px;
    font-weight: 800;
}

.permission_denied h3 {
    text-align: center;
    color: #fff;
    font-size: 19px;
    line-height: 23px;
    max-width: 330px;
    margin: 0px auto 30px auto;
    font-family: "Dosis", sans-serif;
    font-weight: 400;
}

.permission_denied h3 span {
    position: relative;
    width: 65px;
    display: inline-block;
}

.permission_denied h3 span:after {
    content: "";
    border-bottom: 3px solid #ffbb39;
    position: absolute;
    left: 0;
    top: 43%;
    width: 100%;
}

.denied__link {
    background: none;
    color: #fff;
    padding: 12px 0px 10px 0px;
    border: 1px solid #fff;
    outline: none;
    border-radius: 7px;
    width: 150px;
    font-size: 15px;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    display: block;
    margin-bottom: 40px;
    margin-top: 10px;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

.denied__link:hover {
    color: #ffbb39;
    border-color: #ffbb39;
    cursor: pointer;
    opacity: 1;
}

.permission_denied .stars {
    animation: sparkle 1.6s infinite ease-in-out alternate;
}

@keyframes sparkle {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

#astronaut {
    width: 43px;
    position: absolute;
    right: 20px;
    top: 210px;
    animation: spin 4.5s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@media (max-width: 600px) {
    .permission_denied h1 {
        font-size: 75px;
    }
    .permission_denied h3 {
        font-size: 16px;
        width: 200px;
        margin: 0 auto;
        line-height: 23px;
    }
    .permission_denied h3 span {
        width: 60px;
    }
    #astronaut {
        width: 35px;
        right: 40px;
        top: 170px;
    }
}

.saturn,
.saturn-2,
.hover {
    animation: hover 2s infinite ease-in-out alternate;
}

@keyframes hover {
    0% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(-3px);
    }
}

